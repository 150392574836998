
/* 1: 1.4 ratio*/
.card{
    max-height: 16.8rem;
    min-height: 16.8rem;
    max-width: 12rem;
    min-width: 12rem;
    cursor: grab;
}

.highlightedCard{
    max-height: 16.8rem;
    min-height: 16.8rem;
    max-width: 12rem;
    min-width: 12rem;
    cursor: grab;
    border-radius: 10px;
    outline: 2px  #007bff solid;
}

/* Inside Modal (I think)*/
.versionSelector {
    display: flex;
}

.versionCardImage{
    float: center;
}

.versionInfo {
    transform: translate(30%, -550%);
    margin: auto;

}
.updateVersionButton { 
    margin: auto;
    transform: translate(0, 360%);
}

/* Outside the Modal  */

.buttonsBar {
    transform: translate(0, -350%);
}
.cardImage {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}


.cardDetailButton {
    visibility: hidden;
    height:20px;
    width: 20px;
    transform: translate(-875%, 10%);
    z-index: -1000;
}

.removeCardButton {
    visibility: hidden;
    height:20px;
    width: 20px;
    transform: translate(-25%, 0);
}

.copyCardButton {
    visibility: hidden;
    height:20px;
    width: 20px;
    transform: translate(-150%, 0);
}

.parentContainer{
    max-width: 12.5rem;
    min-width: 12.5rem;
    height: 2rem;
    cursor: grab;
    display: flex;
    flex-wrap: wrap;
}

.parentContainer:hover {
    .cardDetailButton, .removeCardButton, .copyCardButton {
        visibility: visible;
        z-index: 1000;
        background-color: #1677ff;
        margin-top: 6px;
        border-radius: 2px; 
        width: 20px;
    }
}

.parentContainer:hover .cardDetailButton {
    margin-left: 11rem;
    display: inline-block;
}

.parentContainer:hover .removeCardButton {
    margin-left: 0.5rem;
}

.parentContainer:hover .copyCardButton {
    margin-left: 2rem;
}

.parentContainer:hover .cardDetailButton:hover {
    cursor: pointer;
}

.parentContainer:hover .removeCardButton:hover {
    cursor: pointer;
}

.parentContainer:hover .copyCardButton:hover {
    cursor: pointer;
}





.opaque{
    filter: grayscale(50%) brightness(50%);
}

.primary {
    opacity: 100% !important;
}

.settingsModal {
    overflow-y: auto;
}