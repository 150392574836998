
.card{
    max-height:  17.5rem;
    min-height:  17.5rem;
    max-width: 12.5rem;
    min-width: 12.5rem;
    border-radius: 13px;
}

.panelWrapper {
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-right: 1.5rem;
    padding-left: 3rem;
    position:sticky;
    justify-content: center;
    top: 46px;
    z-index: 10;
    height: calc(100vh - 46px - 4rem);

}


.title{
    font-family:  "Times New Roman", Times, serif;
    font-size: 12px;
}
.paragraph {
    font-family:  "Times New Roman", Times, serif;
    font-size: 12px;
}

.powerToughness {
    font-weight: bold;
}


.floatButton{
    left: 0
}


.cardPrices {
    content: "";
    display: table;
    clear: both;
    overflow: auto;
    font-size: 14px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 4px; 
    width: 100%;
    min-height: 12rem;
}



.cardDescription{
    content: "";
    display: table;
    clear: both;
    overflow: auto;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 4px; 
    width: 100%;
    min-height: 12rem;

}

.additionalInfo{
    content: "";
    display: table;
    clear: both;
    overflow: auto;
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 4px; 
    width: 100%;
    min-height: 12rem;
}
.panelToolbar{
    background: linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%);
}


.buttonGroupButton:hover{
    background-color: white;
}


.svg{
   height: 1rem;
   width: 1rem;
}

.svg:hover{
 }