.topMenuWrapper {
    display: block;
    height: 46px;
    margin-left: 10rem;
}

.panel{
    width: auto;
    height: 100vh;
    float: left;
}
