.wrapper{
    padding-top: 3rem;
}

.card {
    text-decoration: none;

}

.blog_card {
    text-decoration: none;
    min-width: 30vh;
    max-width: 30vh;
}

a {
    text-decoration: none;
}

.grid_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.grid_card {
    margin-top: 10px;
    padding: 10px;
}

.grid_card_bigger {
    margin-top: 10px;
    padding: 10px;
    max-height: 203.4px;
}

.badge {
    margin-right: 20px;
}