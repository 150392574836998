.defaultSortOrder{
    margin-left: 1rem;
    margin-right: 1rem;
    cursor: pointer !important;
    background-color: 	black; 
    border-color: black;
    color: #fff;
}

.defaultSortOrder:hover{
    background-color: #a9a9a9; 
    border-color: #a9a9a9;
    color: black !important;
    cursor: pointer;
}