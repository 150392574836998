.card{
    max-height: 16.8rem;
    min-height: 16.8rem;
    max-width: 12rem;
    min-width: 12rem;
    cursor: grab;
}


.commandZone {
    border: solid black;
    height: 278px;
    width: 208px;
    padding: 2px;
    border-radius: 8px;
    background-color: #fafcff;
}


.modalClicker{
    height: 300px;
}