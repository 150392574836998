.bannerWrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 10rem;
    background-position: right;
    background-repeat: no-repeat;
    background-position-y: 20%;
    flex-direction: row;
    align-content: stretch;
}

.bannerInputsWrapper {
    margin-left: 1rem;
    margin-top: 7rem;
    float: bottom;
    display: inline;
}

.bannerLeftBlock {
    float:left;
    width: auto;
    display: flex;
    flex-grow: 1;    
    background: linear-gradient(111.5deg, rgb(187, 232, 255) 11.1%, rgb(4, 78, 116) 107.5%);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.bannerRightBlock {
    display: flex;
    width: 27rem;
    height: 10rem;
    background-position: right;
    background-repeat: no-repeat;
    background-position-y: 20%;  
    z-index: 10;
}