.content{
    display:flex;
    
}

.editor{
    width: 100%;
    height: 100%;
    background-color: #fafcff;
}

.header{
    width: 100%;
    height: auto;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #001529;
}

.footer{
    position: absolute;
    float: bottom;
    bottom: 0;
    width: 100%;
}
