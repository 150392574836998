.advImage {
    background-color: red;
    display: flex;
    background-size: 300px;
    width:300px; /* you can use % */
    height: 300px;
    background-position: left;
    background-repeat: no-repeat;
    z-index: 10;
    margin-left: 0.5rem;
}
