.displayButton{
    cursor: pointer !important;
    background-color: black; 
    border-color: black;
    color: #fff;
}

.displayButton:hover{
    background-color: #a9a9a9; 
    border-color: #a9a9a9;
    color: black !important;
    cursor: pointer;
}
