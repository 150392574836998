.toolbar {
    background-color: #13274F;
    padding: 1rem;
    margin-top: 2rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}



.toolbarDiv{
    float: right;
    
}

[type=button] {
    color: #fff;
    background-color: #1677ff;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

[type=button]:active {
    background-color:#13274F;
}

.toolbarButton{
    margin-left: 1rem;
    margin-right: 1rem;
    cursor: pointer !important;
    background-color: #808080; /* Light gray */
    border-color: #808080;
    color: #fff;
    
}

.toolbarButton:hover{
    background-color: #a9a9a9; /* Lighter gray */
    border-color: #a9a9a9;
    color: #fff !important;
    cursor: pointer;
}

.saveButton{

}

.addColumnButton{
    margin-left: 2rem;
}

