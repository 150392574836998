* {
    margin: 0;
    padding: 0;
}

.wrapper{
    display: flex;
    flex-wrap: wrap;
}

.container{
    display: flex;
    padding: 1rem;
    min-height: 200vh;
    border-image: linear-gradient(#a8bac0, #0c649f) 30;
    border-width: 4px;
    border-style: solid;
    overflow: scroll;
}

.panel{
    width: 17rem;
    position: sticky;
    top: 46px;
    margin-top: 14rem;
    display:inline-block;
    vertical-align: top;
}

.rightSide{
    display:inline-block;
    width: calc(100vw - 18rem);
}



.commandZoneWrapper {
    display: block;
}
.commandzone {
    display: inline-block;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem;
    border: solid;
    width: auto;
}



.wrapper{
    font-size: 0;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
    height: 4px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #000000;
    border-radius: 4px;
}


@media only screen and (max-width: 767px) {
    .sidePanelMobile {
        display: none; /* Hide on mobile by default */
    }
    .rightSideMobile{
        width: 100vw !important;
    }

}