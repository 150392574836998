.wrapper{
    padding-top: 3rem;
    white-space: pre-wrap;
    word-wrap: break-word; 
}

.textbody {
    white-space: pre-wrap;
    word-wrap: break-word; 
}

.card {
    display: flex;
    justify-content: center;
}

.command_fest_pics {
    transform: rotate(270deg);
    
    justify-content: center;
    margin-left: 5vh;
}

.command_fest_pics_duals {
    transform: rotate(270deg);
    justify-content: center;
    margin-left: 7vh;
}

.command_fest_pics_artists {
    transform: rotate(270deg);
    justify-content: center;
    margin-left: 7vh;
}
