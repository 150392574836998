.commandZoneWrapper {
    display: flex;
}
.carouselDiv {
    display: flex;
    margin-bottom: 2rem;
    margin: 1rem;
    height: 280px;
    width: 100%;
}

.primer{
    margin-left: 2.5rem;
    width:100%;
    align-items: left;
    text-align: left;
}

.deckInfo{
    display: flex;
    margin-bottom: 2rem;
    border: solid black;
    margin-left: 1rem;
    margin-top: 1rem;
    height: 278px;
    width: 202px;
    padding: 2px;
    border-radius: 8px;
}

.deckCode{
    height: 6rem;
    margin-left: 2.5rem;
    align-items: left;
    text-align: left;
}


.metaDataContainer{
    width: 100%;
}